<template>
  <footer class="flex flex-col md:flex-row flex-wrap bg-primary-darker text-white pt-16 pb-16">
    <section class="flex flex-col sm:flex-row w-full md:w-1/2 md:border-r-2 md:border-secondary pl-10 md:pl-56 pr-10 py-6">
      <div v-for="(group, index) in menuFooter" :key="index" class="w-full sm:w-1/2 sm:text-center md:text-left">
        <template v-if="menuItemHasChildMenuWithChildren(group)">
          <div v-for="childGroup in group.children" :key="childGroup.text">
            <h4>{{ childGroup.text }}</h4>
            <ul class="mb-6">
              <li v-for="child in childGroup.children" :key="child.text" class="text-sm font-bold pb-6 md:pb-4">
                <nuxt-link :to="localePath(child.url)" class="text-white hover:text-secondary">
                  {{ child.text }}
                </nuxt-link>
              </li>
            </ul>
          </div>
        </template>
        <template v-else>
          <h4>{{ group.text }}</h4>
          <ul>
            <li v-for="child in group.children" :key="child.text" class="text-sm font-bold pb-6 md:pb-4">
              <nuxt-link :to="localePath(child.url)" class="text-white hover:text-secondary">
                {{ child.text }}
              </nuxt-link>
            </li>
          </ul>
        </template>
      </div>
    </section>
    <hr class="md:hidden mx-6 border-t-2 border-secondary">
    <section class="flex flex-col md:flex-row w-full items-center md:items-start md:w-1/2 px-10 md:px-24 py-6">
      <div class="w-full sm:w-1/2 md:pr-8 pb-10 md:pb-0">
        <h4>Over NOESK®</h4>
        <ul>
          <li class="text-sm">
            <div class="pb-6">
              {{ getSetting('beschrijving') }}
            </div>
            <a href="https://noesk.com" target="_blank" class="w-full btn btn-secondary">
              Meer over NOESK
            </a>
          </li>
        </ul>
      </div>
      <div class="w-full sm:w-1/2">
        <h4>NOESK®</h4>
        <ul>
          <li class="text-sm">
            <div class="pb-6">
              {{ getSetting('address') }}<br>
              {{ getSetting('postalcode') }}
              {{ getSetting('city') }}<br>
              {{ getSetting('phone') }}<br>
              {{ getSetting('email') }}<br>
              {{ getSetting('website') }}
            </div>
            <nuxt-link :to="localeRoute({name: 'contact'})" class="w-full btn btn-secondary">
              Contact
            </nuxt-link>
          </li>
        </ul>
      </div>
    </section>
    <section class="hidden md:flex flex-col items-center w-full pt-12">
      <img :src="require('~/assets/img/logo.png')" :alt="getSetting('name')" class="w-32">
      <div class="pt-5">
        Copyright {{ getSetting('name') }} © {{ new Date().getFullYear() }} - <a href="https://www.noesk.com/algemene-voorwaarden" target="blank">Algemene voorwaarden</a>
      </div>
    </section>
  </footer>
</template>

<script>
import {mapState} from 'vuex';
import page from '~/plugins/mixins/page';

export default {
  mixins: [page],
  computed: {
    ...mapState({
      settings: state => state.settings,
      menuFooter: state => state.menuFooter,
    }),
  },
  methods: {
    getSetting(key) {
      return this.settings.find(setting => setting.key === key)?.value;
    },
    menuItemHasChildMenuWithChildren(menu) {
      if (!menu.children.length) {
        return false;
      }

      return menu.children[0].children?.length;
    },
  },
};
</script>
