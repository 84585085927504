import Vue from 'vue';
import {library, config} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
  faArrowAltCircleDown,
  faArrowCircleDown,
  faArrowCircleRight,
  faInfoCircle,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faChevronDown,
  faChevronUp,
  faPhoneAlt,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faBars,
} from '@fortawesome/pro-light-svg-icons';

config.autoAddCss = false;

Vue.component('font-awesome-icon', FontAwesomeIcon);

library.add(
  faArrowAltCircleDown,
  faArrowCircleDown,
  faArrowCircleRight,
  faBars,
  faChevronDown,
  faChevronUp,
  faInfoCircle,
  faPhoneAlt,
  faTimes,
);
