<template>
  <section
    class="bg-primary transition duration-500 fixed left-0 right-0 z-40 px-4"
    :class="{'bg-opacity-75': !scrolled && isHome}"
  >
    <nav class="container flex justify-between items-center h-20">
      <section class="flex flex-row items-center justify-start h-full">
        <nuxt-link :to="localeRoute({name: 'index'})" aria-label="Ga naar de homepage" class="py-3">
          <img :src="require('~/assets/img/logo.png')" alt="Noesk" aria-label="hidden" class="h-14">
        </nuxt-link>
        <span class="text-secondary hidden sm:inline text-lg font-bold uppercase pl-5">
          Houten poorten
        </span>
      </section>

      <!--   MOBILE MENU   -->
      <section class="lg:hidden flex align-center">
        <button aria-label="Open menu" class="text-4xl text-white z-40" @click="toggle">
          <font-awesome-icon :icon="['fal', 'bars']" aria-hidden="true"/>
        </button>

        <transition name="fade">
          <div v-if="open" class="absolute left-0 top-80 w-screen min-h-screen bg-primary">
            <div class="flex flex-col justify-center items-center h-screen -mt-24 sm:-mt-32">
              <ul class="text-center">
                <li
                  v-for="item in menuMain"
                  :key="item.text"
                  class="py-3"
                >
                  <nuxt-link
                    :to="localePath(item.url)"
                    class="text-white hover:text-secondary uppercase text-xl"
                    @click.native="toggle"
                  >
                    {{ item.text }}
                  </nuxt-link>
                </li>
              </ul>
              <nuxt-link
                :to="localeRoute({name: 'configurator-slug'})"
                class="btn btn-secondary text-sm mt-16"
                @click.native="toggle"
              >
                Start de configurator
              </nuxt-link>
              <div class="absolute bottom-20v">
                <div class="text-secondary italic pb-2">
                  Persoonlijk advies?
                </div>
                <a
                  :href="`tel:${getSetting('phone')}`"
                  aria-label="Neem contact met ons op"
                  target="_blank"
                  rel="noopener"
                  class="font-heading"
                >
                  <font-awesome-icon :icon="['fas', 'phone-alt']" class="mr-1" aria-hidden="true"/>
                  {{ getSetting('phone') }}
                </a>
              </div>
            </div>
          </div>
        </transition>
      </section>

      <ul class="hidden lg:flex h-full">
        <li v-for="item in menuMain" :key="item.text" class="nav h-full">
          <nuxt-link
            :to="localePath(item.url)"
            class="text-white hover:text-secondary uppercase mr-12 relative h-full flex items-center"
          >
            {{ item.text }}
          </nuxt-link>
        </li>
      </ul>

      <div class="hidden lg:flex flex-col">
        <span class="text-secondary italic">
          Persoonlijk advies?
        </span>
        <a
          :href="`tel:${getSetting('phone')}`"
          aria-label="Neem contact met ons op"
          target="_blank"
          rel="noopener"
          class="font-heading"
        >
          <font-awesome-icon
            :icon="['fas', 'phone-alt']"
            class="mr-1"
            aria-hidden="true"
          />
          {{ getSetting('phone') }}
        </a>
      </div>
    </nav>
  </section>
</template>

<script>
import {mapState} from 'vuex';

export default {
  data() {
    return {
      open: false,
      subItems: false,
      scrolled: false,
    };
  },
  computed: {
    ...mapState({
      settings: state => state.settings,
      menuMain: state => state.menuMain,
    }),
    isHome() {
      return this.$nuxt.$route.name === 'index';
    },
  },
  mounted: function () {
    window.addEventListener('scroll', this.scrollHandler);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
  methods: {
    getSetting(key) {
      return this.settings.find(setting => setting.key === key)?.value;
    },
    toggle() {
      this.open = !this.open;
    },
    showSubItems() {
      this.subItems = !this.subItems;
    },
    scrollHandler: function () {
      this.scrolled = window.scrollY >= 150;
    },
  },
};
</script>

<style scoped>
.nav {
  ul {
    display: none;
    opacity: 0;
    transform: translateY(-4px);
  }

  &:hover {
    ul {
      display: block;
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
