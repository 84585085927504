import {nl} from '@braid/vue-formulate-i18n';
import errorHandler from '~/libs/error-handler';

export default {
  plugins: [nl],
  classes: {
    input({classification}) {
      switch (classification) {
        case 'button':
          return 'btn btn-primary';
        case 'box':
          return '';
        default:
          return 'w-full placeholder-primary-lighten1 transition-all duration-300 pb-3';
      }
    },
    label({classification}) {
      switch (classification) {
        case 'box':
          return '';
        default:
          return 'block font-heading text-subtitle font-semibold mb-3';
      }
    },
    element({classification}) {
      switch (classification) {
        case 'button':
          return '';
        default:
          return 'shadow-b-2 hover:shadow-b-3 focus-within:shadow-b-3 transition-all duration-300';
      }
    },
    outer({classification}) {
      switch (classification) {
        case 'button':
          return '';
        default:
          return 'mb-5';
      }
    },
    help: 'text-sm mb-1',
    error: 'text-red text-xs my-1',
  },
  errorHandler,
};
