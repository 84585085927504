import {ApolloLink, split} from 'apollo-link';
import {createUploadLink} from 'apollo-upload-client';
import {BatchHttpLink} from 'apollo-link-batch-http';
import {getMainDefinition} from 'apollo-utilities';
import {onError} from 'apollo-link-error';

export default function (app) {
  const errorLink = onError(({graphQLErrors}) => {
    if (graphQLErrors) {
      graphQLErrors.map((error) => {
        if (error.message === 'validation') {
          app.store.dispatch('validation/setErrors', error.extensions.validation);
        }
      });
    }
  });

  const linkOptions = {
    uri: process.env.API_URL.replace('/api', '') + '/graphql',
    fetch: (uri, options) => {
      app.store.dispatch('validation/clearErrors');

      // Add authorization token
      if (app.$cookies) {
        const token = app.$cookies.get('auth._token.local');

        if (token) {
          options.headers.authorization = token;
        }
      }

      return fetch(uri, options);
    },
  };

  // See https://dev.to/marvinrabe/file-upload-with-vue-apollo-client-and-graphql-5emb
  const uploadLink = createUploadLink(linkOptions);

  // See https://stackoverflow.com/questions/63244686/vue-apollo-composite-oe-batch-queries-possible
  const batchLink = new BatchHttpLink(linkOptions);

  const link = split(
    // Split based on operation type
    ({query}) => {
      const {kind, operation} = getMainDefinition(query);

      return kind === 'OperationDefinition' && ['mutation', 'subscription'].includes(operation);
    },
    uploadLink,
    batchLink,
  );

  return {
    defaultHttpLink: false,
    inMemoryCacheOptions: {
      addTypename: false,
    },
    link: ApolloLink.from([
      errorLink,
      link,
    ]),
  };
}
