<template>
  <div>
    <template v-if="error.statusCode === 410 || error.statusCode === 404">
      <div
        class="mt-80p py-48 flex flex-col items-center bg-cover text-white"
        :style="`background-image: url('${require('~/assets/img/bg.png')}');`"
      >
        <h1 class="uppercase">
          {{ page.title }}
        </h1>
        <!--eslint-disable-next-line-->
        <div class="pt-8 pb-12" v-html="page.template.text"/>
        <nuxt-link class="btn btn-secondary" :to="localeRoute({name: 'index'})">
          Terug naar home
        </nuxt-link>
      </div>
    </template>
    <h1 v-else>
      Er is een fout opgetreden
    </h1>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['page']),
  },
  async created() {
    await this.$store.dispatch('getPage', '404');
  },
  head() {
    return {
      title: this.page.seo ? this.page.seo.meta_title : this.page?.title,
    };
  },
};
</script>
