import Vue from 'vue';
import {InMemoryCache} from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo';
import {createApolloClient, handleSmartQuery} from '/data/www/noesk.com/noesk-autodeploy/releases/20240521-075301_5e6b2810/nuxt/node_modules/@bttr_nl/apollo-module/lib/helpers.js';

Vue.use(VueApollo);

const options = {
  "clientConfigs": {
    "default": "./apollo-config.js"
  },
  "authenticationType": "Basic",
  "configPath": "/data/www/noesk.com/noesk-autodeploy/releases/20240521-075301_5e6b2810/nuxt/apollo-config.js",
  "modulePath": "/data/www/noesk.com/noesk-autodeploy/releases/20240521-075301_5e6b2810/nuxt/node_modules/@bttr_nl/apollo-module/lib",
  "helperPath": "/data/www/noesk.com/noesk-autodeploy/releases/20240521-075301_5e6b2810/nuxt/node_modules/@bttr_nl/apollo-module/lib/helpers.js"
}

export default (ctx, inject) => {
  const ssr = process.server;
  let defaultClientConfig;

  if (typeof options.clientConfigs.default === 'object') {
    defaultClientConfig = 2
  } else {
    const {default: config} = require('/data/www/noesk.com/noesk-autodeploy/releases/20240521-075301_5e6b2810/nuxt/apollo-config.js');
    defaultClientConfig = config(ctx);
  }

  const cache = defaultClientConfig.cache ? defaultClientConfig.cache : new InMemoryCache();

  // This should only be executed client side and we should check if we have a set apollo state
  if (!process.server && window.__NUXT__.apollo) {
    cache.restore(window.__NUXT__.apollo.defaultClient);
  }

  const apolloClient = createApolloClient({
    ...defaultClientConfig,
    ssr,
    cache,
  });

  const providerOptions = {
    defaultClient: apolloClient.apolloClient,
    errorHandler(error) {
      console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    },
  };

  const apolloProvider = new VueApollo(providerOptions);

  ctx.app.apolloProvider = apolloProvider;

  if (process.server) {
    const ApolloSSR = require('vue-apollo/ssr');
    ctx.beforeNuxtRender(async ({nuxtState, Components}) => {
      // We set the apollo state here because setting it after all the queries will cause the state to never be set when there is a serviceworker
      nuxtState.apollo = ApolloSSR.getStates(apolloProvider);

      if (Components.length === 0) {
        return;
      }

      const pageComponent = {
        ...Components[0],
        ...Components[0].options.data(),
      };
      pageComponent.$nuxt = ctx;
      pageComponent.$route = ctx.route;

      const queries = pageComponent.options.apollo;

      for (const name in queries) {
        if (name.charAt(0) === '$') return;

        const query = queries[name];
        await handleSmartQuery(pageComponent, name, query, apolloProvider);
      }
    });
  }
}
