const scrollToError = () => {
  // TODO: pass form as argument, so we can handle multiple forms on the page
  const errorWrapperEl = document.querySelector('[data-has-errors="true"]');
  const errorWrapperInput = errorWrapperEl && errorWrapperEl.querySelector('input,select,textarea');
  if (errorWrapperInput) {
    errorWrapperInput.scrollIntoView({block: 'center', behavior: 'smooth'});
    errorWrapperInput.focus();
  }
};

// Todo: formErrors are always set to null, maybe we can use them in $toast?
export default ({graphQLErrors, response}) => {
  let validation = null;

  if (graphQLErrors) {
    const validationErrorObject = graphQLErrors.find(error => error.message === 'validation');
    if (validationErrorObject) {
      const {extensions} = validationErrorObject;
      validation = extensions && extensions.validation;
    }
  }

  if (validation) {
    scrollToError();
    return {
      inputErrors: validation,
      formErrors: null,
    };
  } else if (response && response.status) {
    scrollToError();
    switch (response.status) {
      case 422:
        return {
          inputErrors: response.data.errors,
          formErrors: null,
        };
    }
  } else {
    return {
      inputErrors: {},
      formErrors: null,
    };
  }
};
